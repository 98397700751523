import useOutsideClick from "hooks/useOutsideClick";
import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { setMainState } from "redux/slices/main";
import { MEDIA } from "service/routes";

const User = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showUserbox, setShowUserbox] = useState(false);
    const wrapperRef = useRef(null);
    const userInfo = useSelector(state => state.main.info);
    useOutsideClick(wrapperRef, () => setShowUserbox(false));

    const list = [
        {
            icon: "fa-user",
            title: "پروفایل من",
            link: "/panel/userProfile"
        },
        {
            icon: "fa-headset",
            title: "پشتیبانی",
            link: "/panel/ticket"
        },
        {
            icon: "fa-unlock",
            title: "تنظیمات رمزعبور",
            link: "/panel/password-setting"
        },
        {
            icon: "fa-power-off",
            title: "خروج",
            clickHandler: () => {
                dispatch(setMainState({
                    authorizationProcess: true,
                    authorization: false,
                    accessToken: null
                }));

                localStorage.removeItem("_rt");
                localStorage.removeItem("_rtet");
                localStorage.removeItem("_at");
                localStorage.removeItem("_atet");

                navigate("/auth/login", { replace: true });
            }
        },
    ]

    return (
        <div className="userbox relative mr-3" ref={wrapperRef}>
            <div className="ui-box">
                <img
                    src={
                        userInfo.fetched && !userInfo.error && userInfo.data?.pic?.length
                            ? `${MEDIA}/${userInfo.data.pic.replace("/storage", "")}`
                            : "/assets/image/def-user.png"
                    }
                    alt="user"
                    onClick={() => setShowUserbox(prev => !prev)}
                    title="ناحیه کاربری"
                    className="w-full h-full object-cover"
                />

                <CSSTransition in={!userInfo.fetched} timeout={300} unmountOnExit={true} appear={true}>
                    <div className="pos-cover fit-center loading-c3">
                        <i className="fa-solid fa-spinner spin"></i>
                    </div>
                </CSSTransition>
            </div>

            <CSSTransition in={showUserbox} timeout={{ enter: 200, exit: 0 }}>
                <div className="dropdown absolute left-0 anim-c2">
                    <div className="flex flex-col items-center py-4 relative bg-white dark:bg-secondary">
                        <b className="text-[#11141F] dark:text-[#D0D0E2] mb-1">
                            {
                                userInfo.fetched ? (
                                    !userInfo.error ? (
                                        `${userInfo.data.name || ""} ${userInfo.data.last_name || ""}`
                                    ) : <button className="text-xl"><i className="fa-solid fa-rotate-right"></i></button>
                                ) : "---"
                            }
                        </b>

                        <span className="text-[#3C4858] dark:text-[#D0D0E2] text-xs font-medium">مدیر</span>
                    </div>

                    <ul>
                        {
                            list.map((item, i) => (
                                <li key={`userbox-item-${i}`}>
                                    {
                                        item.clickHandler ? (
                                            <button className="c-c1" onClick={item.clickHandler}>
                                                <span className="icn-box">
                                                    <i className={`fa-light ${item.icon}`}></i>
                                                </span>

                                                <span className="sp-1">
                                                    {item.title}
                                                </span>
                                            </button>
                                        ) : (
                                            <Link to={item.link} className="c-c1" onClick={() => setShowUserbox(false)}>
                                                <span className="icn-box">
                                                    <i className={`fa-light ${item.icon}`}></i>
                                                </span>

                                                <span className="sp-1">
                                                    {item.title}
                                                </span>
                                            </Link>
                                        )
                                    }
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </CSSTransition>
        </div>
    )
}

export default User;