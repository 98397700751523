import { memo, useCallback, useContext, useEffect, useState } from "react";
import Breadcrumb from "components/shared/breadcrumb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Form from "../form/form";
import { useSearchParams } from "react-router-dom";
import MasterAPI from "service/master";
import { toast } from "react-toastify";
import Loading from "components/shared/loading";
import InitializeContext from "context/initialize-context";

const Edit = props => {
    const { pageData, id } = props;
    const enums = useContext(InitializeContext);

    const [breadcrumb, setBreadcrumb] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [goSubmit, setGoSubmit] = useState(0);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [defaultValues, setDefaultValues] = useState({});
    const [defaultCats, setDefaultCats] = useState([]);
    const navigate = useNavigate();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [draftData, setDraftData] = useState({});
    const [rejectLoading, setRejectLoading] = useState(false);
    const [targetCategory, setTargetCategory] = useState("");
    const [data, setData] = useState({});
    const [readFromDraft, setReadFromDraft] = useState(true);
    const [fetchLoading, setFetchLoading] = useState(true);
    const [activeFieldIds, setActiveFieldIds] = useState([0]);

    useEffect(() => {
        if (pageData.breadcrumb) {
            setBreadcrumb([
                ...pageData.breadcrumb,
                { title: "ویرایش" }
            ]);
        }
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        defaultValueFetcher();
        // eslint-disable-next-line
    }, [readFromDraft])

    const defaultValueFetcher = async () => {
        try {
            let url = pageData.get_url;
            url = url.replace(":id", id);
            url = url.replace(":form_id", searchParams.get("form_id"));

            const res = await MasterAPI({
                url,
                method: "GET"
            });

            setData(res);
            if (res.data) {
                let resData = res.data;

                if (readFromDraft && res.draft?.content)
                    resData = res.draft.content;

                setDefaultValues({
                    ...resData.fields,
                    provider_id: resData.provider?.id
                });

                setTargetCategory(resData.target_category || "");
                setDefaultCats(resData.categories || []);
                setDraftData(res.draft || {});

                const permissionsIds = await getPermissions(res);
                if (permissionsIds === "all_access")
                    setActiveFieldIds(null);
                else if (permissionsIds?.length)
                    setActiveFieldIds(prev => [...prev, ...permissionsIds]);

                setFetchLoading(false);
            } else
                throw Object.assign(new Error("no fields"), { code: 402 });
        } catch (e) {
            toast.error(e.message);
        }
    }

    const getPermissions = async defaultValuesParam => {
        if(defaultValuesParam.data.group_type === 0)
            return "all_access";

        try {
            const { id: userId, user_kind: userKind } = defaultValuesParam.data.creator;
            if (!defaultValuesParam.creator && userKind !== 4 && userKind !== 3) {
                const res = await MasterAPI({
                    url: `/api/permission/intersection_permissions?user_kind=${userKind}&user_id=${userId}`,
                    method: "GET"
                });

                if (res.permissions.all_access)
                    return "all_access";

                return res.permissions.forms_group_policies[defaultValuesParam.data.form_id].elemets_exclude_group_template;
            } else {
                if (enums.permissions.all_access)
                    return "all_access";

                return enums.permissions.forms_group_policies[defaultValuesParam.data.form_id].elemets_exclude_group_template;
            }
        } catch {
            return null;
        }
    }

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.pop();

        return `${splitedRoute.join("/")}?form_id=${searchParams.get("form_id")}`;
    }

    const setParentLoading = useCallback(value => {
        setSubmitLoading(value);
        // eslint-disable-next-line
    }, [])

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    const doneCallback = useCallback(() => {
        navigate(getPreviousRoute());
        // eslint-disable-next-line
    }, []);

    const confirmHandler = async () => {
        if (confirmLoading)
            return;

        setConfirmLoading(true);
        try {
            const res = await MasterAPI({
                url: `/api/${draftData.id ? "draft" : "content"}/change_status`,
                payloadType: 3,
                method: "PUT",
                data: {
                    form_id: parseInt(searchParams.get("form_id")),
                    status_id: draftData.id ? 1 : (data.status_id === 1 ? 0 : 1),
                    ids: [draftData.id ? draftData.id : id]
                },
                callback: () => setConfirmLoading(false)
            });

            toast.success(res.message);
            navigate(`/panel/content?form_id=${searchParams.get("form_id")}`);
        } catch (e) {
            toast.error(e.message);
        }
    }

    const rejectHandler = async () => {
        if (rejectLoading)
            return;

        setRejectLoading(true);
        try {
            const res = await MasterAPI({
                url: "/api/draft/change_status",
                payloadType: 3,
                method: "PUT",
                data: {
                    form_id: parseInt(searchParams.get("form_id")),
                    status_id: 2,
                    ids: [draftData.id]
                },
                callback: () => setRejectLoading(false)
            });

            toast.success(res.message);
            navigate(`/panel/content?form_id=${searchParams.get("form_id")}`);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">{pageData.title}</h1>
                    <Breadcrumb data={breadcrumb} />
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    {
                        !!draftData.id && (
                            <button
                                className={`bg-primary-100 hover:bg-primary-200 px-5 transition-colors text-white rounded-md fit-center text-sm h-10 relative ml-2 overflow-hidden${rejectLoading ? " cursor-progress" : ""}`}
                                onClick={rejectHandler}
                            >
                                <i className="fa-light fa-thumbs-down ml-2"></i>
                                رد تغییرات

                                <CSSTransition in={rejectLoading} timeout={200} unmountOnExit={true}>
                                    <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                        <i className="fa-solid fa-spinner spin"></i>
                                    </span>
                                </CSSTransition>
                            </button>
                        )
                    }

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-5 transition-colors text-white rounded-md fit-center text-sm h-10 relative ml-2 overflow-hidden${confirmLoading ? " cursor-progress" : ""}`}
                        onClick={confirmHandler}
                    >
                        <i className="fa-light fa-check ml-2"></i>
                        {draftData.id ? "تایید تغییرات" : (data.status_id === 1 ? "رد محتوا" : "تایید محتوا")}

                        <CSSTransition in={confirmLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-5 transition-colors text-white rounded-md fit-center text-sm h-10 ml-2 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-pen-to-square ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>

                    {
                        !fetchLoading && (
                            <Link to={`/panel/duplicate/${data.data.group_id}?form_id=${data.data.form_id}`} className="bg-primary-100 hover:bg-primary-200 px-5 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden">
                                <i className="fa-light fa-layer-group ml-2" />
                                گروه بندی محتوا
                            </Link>
                        )
                    }
                </div>
            </div>

            {
                data.draft?.content && (
                    <div className="wrap-box tp-22 page-transition form-default-tabs mb-4">
                        <div className="grid grid-cols-2 gap-3">
                            <button className={`m-block${readFromDraft ? " active" : ""}`} onClick={() => setReadFromDraft(true)}>
                                نمایش اطلاعات draft
                            </button>

                            <button className={`m-block${!readFromDraft ? " active" : ""}`} onClick={() => setReadFromDraft(false)}>
                                نمایش اطلاعات content
                            </button>
                        </div>
                    </div>
                )
            }

            <div className="wrap-box tp-22 page-transition">
                {
                    !fetchLoading ? (
                        <Form
                            id={id}
                            formId={searchParams.get("form_id")}
                            setParentLoading={setParentLoading}
                            goSubmit={goSubmit}
                            defaultValues={defaultValues}
                            mode="edit"
                            doneCallback={doneCallback}
                            defaultCats={defaultCats}
                            targetCategory={targetCategory}
                            compareWith={readFromDraft ? data.data?.fields : data.draft?.content?.fields}
                            activeIds={activeFieldIds}
                        />
                    ) : (
                        <div className="fit-center">
                            <Loading className="loading-c5" />
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default memo(Edit);