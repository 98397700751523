import Breadcrumb from "components/shared/breadcrumb";
import { memo, useState } from "react";
import { toast } from "react-toastify";
import { CSSTransition } from "react-transition-group";
import MasterAPI from "service/master";

const buttonsList = [
    { title: "بروزرسانی سایت اصلی", key: "website_ui" },
    { title: "بروزرسانی پنل ادمین", key: "control_panel_ui" },
    { title: "بروزرسانی پنل تامین کنندگان", key: "vendor_ui" }
];

const UpdateApps = () => {
    const [loading, setLoading] = useState([]);

    const clickHandler = async serviceName => {
        if (loading.indexOf(serviceName) !== -1)
            return;

        setLoading(prev => [...prev, serviceName]);
        try {
            const res = await MasterAPI({
                url: "/api/setting/ui_cache/reset",
                method: "PUT",
                payloadType: 3,
                data: {
                    service_name: serviceName
                },
                callback: () => setLoading(prev => prev.filter(el => el !== serviceName))
            });

            toast.success(res.message);
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <div className="m-container">
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">بروزرسانی اپلیکیشن ها</h1>
                    <Breadcrumb data={[
                        { title: "ابزارها" },
                        { title: "تنظیمات سرور", link: "/panel/server-settings" },
                        { title: "بروزرسانی اپلیکیشن ها" }
                    ]} />
                </div>
            </div>

            {
                buttonsList.map(buttonEl => (
                    <div className="wrap-box tp-22 page-transition mb-4 flex justify-between items-center" key={`reset-box-${buttonEl.key}`}>
                        <span className="text-sm font-medium text-slate-800 dark:text-slate-100">{buttonEl.title}</span>

                        <button
                            className="bg-primary-100 hover:bg-primary-200 transition-colors rounded-md text-white text-sm py-2 px-4 overflow-hidden relative"
                            onClick={() => clickHandler(buttonEl.key)}
                        >
                            بروزرسانی

                            <CSSTransition in={loading.indexOf(buttonEl.key) !== -1} timeout={200} unmountOnExit={true}>
                                <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                    <i className="fa-solid fa-spinner spin"></i>
                                </span>
                            </CSSTransition>
                        </button>
                    </div>
                ))
            }
        </div>
    )
}

export default memo(UpdateApps);