export const fileIdentityContentType = {
    undefined:"نامشخص",
    0: "نامشخص",
    1: "تصویر",
    2: "صوت",
    3: "ویدئو",
    4: "سند",
    5:"فشرده شده"
}
export const fileIdentityUsageType = {
    0: "فاقد اتصال",
    1: "محتوا",
    2: "واریز به حساب",
    3: "دسته بندی",
    4: "پروفایل مدیر",
    5: "پروفایل کاربر",
    6: "پروفایل تامین کننده",
    7: "شیوه ارسال",
    8: "شیوه پرداخت",
    9: "درگاه",
    10: "نوع اشتراک"
}
export const GetUsageTypeLink = (usage) => {
    if (usage?.usage_type !== undefined) {
        switch (usage.usage_type) {
            case 1:
                return `/panel/content/${usage.oid}?form_id=${usage?.content.form_id}`
            case 2:
                return `/panel/remittance/${usage.id}`
            case 3:
                return `/panel/manageContent/categories/${usage.id}`
            case 4:
                return `/panel/identity_admin/${usage.id}`
            case 5:
                return `/panel/identity_user/${usage.id}`
            case 6:
                return `/panel/identity_provider/${usage.id}`
            case 7:
                return `/panel/shop_setting/transport_type/${usage.id}`
            case 8:
                return `/panel/shop_setting/payment_type/${usage.id}`
            case 9:
                return `/panel/shop_setting/payline/${usage.id}`
            case 10:
                return `/panel/shop_setting/subscription_type/${usage.oid}`
        }
    }
}