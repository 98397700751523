const ticketStatuses = [
    {
        title: "باز",
        icon: "fa-duotone fa-envelope-open text-green-500"
    },
    {
        title: "در انتظار پاسخ",
        icon: "fa-duotone fa-hourglass-clock text-green-500"
    },
    {
        title: "پاسخ داده شده",
        icon: "fa-duotone fa-check text-green-500"
    },
    {
        title: "بسته شده",
        icon: "fa-duotone fa-check-double text-red-500"
    }
]

export default ticketStatuses;