import BreadcrumbSkeleton from "components/skeleton/breadcrumb-1";
import Breadcrumb from "components/shared/breadcrumb";
import { useEffect, useState, memo, useCallback } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import FormGenerator from "components/form-generator/main/main";
import { CSSTransition } from "react-transition-group";
import UseFetch from "hooks/useFetch";

const Edit = props => {
    const {
        pageData,
        id,
        setServerBreadcrumbDataCallback,
        serverBreadcrumb
    } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const [fetchUrl, setFetchUrl] = useState(null);
    const { isLoading, data, error } = UseFetch(fetchUrl);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [goSubmit, setGoSubmit] = useState(0);
    const urlParams = new URLSearchParams(location.search);

    const getPreviousRoute = () => {
        const splitedRoute = location.pathname.split("/");
        splitedRoute.pop();

        let previousRoute = splitedRoute.join("/");
        const validParams = [];

        if (pageData && pageData.add_valid_params)
            pageData.add_valid_params.forEach(param => {
                const paramValue = urlParams.get(param);
                if (paramValue)
                    validParams.push(`${param}=${paramValue}`);
            })

        if (validParams.length)
            previousRoute += `?${validParams.join("&")}`;

        return previousRoute;
    }

    const handlerBack = () => {
        navigate(getPreviousRoute(), { replace: true });
    }

    useEffect(() => {
        if (pageData)
            setFetchUrl(pageData.get_url.replace(":id", id));
        // eslint-disable-next-line
    }, [pageData])

    useEffect(() => {
        if (data && data.breadcrumbs?.length)
            setServerBreadcrumbDataCallback(data.breadcrumbs);
        else
            setServerBreadcrumbDataCallback([]);
        // eslint-disable-next-line
    }, [data])

    const submitHandler = () => {
        setGoSubmit(prev => prev + 1);
    }

    const setParentLoading = useCallback(val => {
        setSubmitLoading(val);
        // eslint-disable-next-line
    }, [])

    const doneCallback = useCallback(() => {
        handlerBack();
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className="flex item-start md:items-center justify-start md:justify-between flex-col md:flex-row mb-6 options-bar">
                <div className="mb-6 md:mb-0">
                    <h1 className="title-c1 tp-21">ویرایش</h1>

                    {
                        !isLoading ? (
                            serverBreadcrumb.length
                                ? <Breadcrumb data={serverBreadcrumb} />
                                : <Breadcrumb data={pageData.breadcrumb} />
                        ) : (
                            <BreadcrumbSkeleton />
                        )
                    }
                </div>

                <div className="flex">
                    <Link to={getPreviousRoute()} className="w-10 h-10 tp-22 text-[#424e79] dark:text-white ml-3 rounded-md fit-center">
                        <i className="fa-regular fa-arrow-right"></i>
                    </Link>

                    <button
                        className={`bg-primary-100 hover:bg-primary-200 px-3 transition-colors text-white rounded-md fit-center text-sm h-10 relative overflow-hidden${submitLoading ? " cursor-progress" : ""}`}
                        onClick={submitHandler}
                    >
                        <i className="fa-light fa-pen-to-square ml-2"></i>
                        ثبت تغییرات

                        <CSSTransition in={submitLoading} timeout={200} unmountOnExit={true}>
                            <span className="pos-cover bg-primary-200 fit-center anim-c4">
                                <i className="fa-solid fa-spinner spin"></i>
                            </span>
                        </CSSTransition>
                    </button>
                </div>
            </div>

            <div className="wrap-box tp-22 page-transition">
                {
                    error && <p className="font-medium text-sm text-red-500 text-center">خطا در دریافت اطلاعات!</p>
                }

                {
                    !isLoading && (
                        <FormGenerator
                            mode="edit"
                            id={id}
                            submitUrl={pageData.edit_url}
                            submitMethod="PUT"
                            fields={pageData.edit_setting}
                            defaultValues={data?.data}
                            goSubmit={goSubmit}
                            setParentLoading={setParentLoading}
                            doneCallback={doneCallback}
                        />
                    )
                }
            </div>
        </>
    )
}

export default memo(Edit);