import moment from "jalali-moment";
import { isObject, urlDecoder } from "utils";

const jsonFieldConverter = value => {
    let output = [];

    if (value && typeof value === "object" && Object.keys(value).length) {
        const newOutput = [];

        Object.keys(value).forEach(key => {
            newOutput.push({
                key: key,
                value: value[key]
            });
        })

        output = newOutput;
    }

    return output;
}

const fileUploadConverter = (value, dataType) => {
    if (dataType === 6) {
        if (Array.isArray(value))
            return value;

        return [];
    }

    if (value?.length)
        return value.split(";");

    return [];
}

const jsonTextConverter = value => JSON.stringify(isObject(value) ? value : {});

const timeConverter = (val, time = false) => {
    try {
        if (!val || val?.length === 0)
            return null;

        let format = "jYYYY/jMM/jDD";
        if (time)
            format += " HH:mm:ss";

        return moment.unix(val).format(format);
    } catch {
        return null;
    }
}

const addressConverter = value => {
    return value ? {
        number: value.detail.number,
        postal_code: value.detail.postal_code,
        unit: value.detail.unit,
        coordinates: [value.latitude, value.longitude],
        address: value.detail.address,
        meta_data: {}
    } : {};
}

const readTimeConverter = (inputType, value, dataType) => {
    switch (inputType) {
        case 4:
            return !!value;
        case 5:
            if (dataType === 6)
                return typeof value === "object" && value?.length === 1 ? value[0] : null;
            else
                return value;
        case 7:
            return value || [];
        case 8:
            return value || [];
        case 9:
        case 10:
            return fileUploadConverter(value, dataType);
        case 13:
            return timeConverter(value);
        case 14:
            return timeConverter(value, true);
        case 15:
            return value ? 1 : 0;
        case 16:
            return jsonFieldConverter(value);
        case 17:
            if (dataType === 6)
                return value?.length ? value[0] : null;
            else
                return value;
        case 19:
            return jsonTextConverter(value);
        case 20:
            return urlDecoder(value);
        case 25:
            return Array.isArray(value) ? value : [];
        case 27:
            return Array.isArray(value) ? value : [];
        case 28:
            return addressConverter(value);
        default:
            return value;
    }
}

export default readTimeConverter;