import InputText from "./input-text";
import Checkbox from "./checkbox";
import Selectbox from "./selectbox";
import { memo } from "react";
import Radio from "./radio";
import CheckboxList from "./checkbox-list";
import SearchSelect from "./search-select";
import DatePicker from "./date-picker";
import FileUpload from "./file-upload";
import Textarea from "./textarea";
import Editor from "./editor";
import JsonField from "./json-field";
import JsonEditor from "./json-editor";
import SectionTitle from "./section-title";
import HtmlEditor from "./html-editor";
import TimeRange from "./time-range";
import ContentsLink from "./contents-link";
import SwitchInput from "./switchInput";
import FormContentsLink from "./formContentsLink/formContentsLink";
import Address from "./address";

const Mapper = props => {

    const {
        field,
        changeHandler,
        targetCategory
    } = props;

    switch (field.input_type) {
        case 1:
            return (
                <InputText
                    changeHandler={val => changeHandler(field.name, val)}
                    value={field.value}
                    label={field.caption}
                    moneyFormat={field.setting?.money_format}
                    id={field.htmlId}
                    masterClass={field.masterclass}
                />
            )
        case 2:
            return (
                <Textarea
                    changeHandler={val => changeHandler(field.name, val)}
                    value={field.value}
                    label={field.caption}
                    id={field.htmlId}
                />
            )
        case 3:
            return (
                <Editor
                    changeHandler={val => changeHandler(field.name, val)}
                    value={field.value}
                    label={field.caption}
                />
            )
        case 4:
            return (
                <Checkbox
                    changeHandler={val => changeHandler(field.name, val)}
                    label={field.caption}
                    value={field.value}
                />
            )
        case 5:
            return (
                <Selectbox
                    changeHandler={val => changeHandler(field.name, val)}
                    options={field.options}
                    label={field.caption}
                    value={field.value}
                    id={field.htmlId}
                />
            )
        case 7:
            return (
                <CheckboxList
                    name={field.name}
                    options={field.options}
                    label={field.caption}
                    value={field.value}
                    masterClass={field.masterclass}
                    changeHandler={val => changeHandler(field.name, val)}
                />
            )
        case 8:
            return (
                <Radio
                    name={field.name}
                    value={field.value}
                    label={field.caption}
                    changeHandler={val => changeHandler(field.name, val)}
                    options={field.options}
                    setting={field.setting}
                />
            )
        case 9:
        case 10:
            return (
                <FileUpload
                    name={field.name}
                    value={field.value}
                    label={field.caption}
                    multi={field.input_type === 10}
                    folder={field.meta_data?.file_folder || field.folder || "public"}
                    changeHandler={val => changeHandler(field.name, val)}
                />
            )
        case 17:
        case 18:
            return (
                <SearchSelect
                    options={field.options}
                    isMulti={field.multi_select}
                    placeholder={field.caption}
                    service={field.url}
                    multi={field.input_type === 18}
                    setting={field.setting}
                    changeHandler={val => changeHandler(field.name, val)}
                    value={field.value}
                    enumerationId={field.enumeration_id}
                    addValueToUrl={field.add_value_to_url}
                    className={field.masterclass}
                />
            )
        case 13:
        case 14:
            return (
                <DatePicker
                    name={field.name}
                    value={field.value}
                    label={field.caption}
                    changeHandler={val => changeHandler(field.name, val)}
                    pickTime={field.input_type === 14}
                    id={field.htmlId}
                />
            )
        case 15:
            return (
                <Checkbox
                    changeHandler={val => changeHandler(field.name, val)}
                    label={field.caption}
                    value={field.value}
                    numeric={true}
                />
            )
        case 16:
            return (
                <JsonField
                    value={field.value}
                    label={field.caption}
                    changeHandler={val => changeHandler(field.name, val)}
                />
            )
        case 19:
            return (
                <JsonEditor
                    value={field.value}
                    label={field.caption}
                    changeHandler={val => changeHandler(field.name, val)}
                />
            )
        case 21:
            return (
                <SectionTitle
                    title={field.caption}
                />
            )
        case 22:
            return (
                <HtmlEditor
                    value={field.value}
                    label={field.caption}
                    changeHandler={val => changeHandler(field.name, val)}
                />
            )
        case 23:
        case 24:
            return (
                <TimeRange
                    value={field.value}
                    label={field.caption}
                    changeHandler={val => changeHandler(field.name, val)}
                    pickTime={field.input_type === 23}
                />
            )
        case 25:
            return (
                <ContentsLink
                    value={field.value}
                    label={field.caption}
                    setting={field.setting}
                    changeHandler={val => changeHandler(field.name, val)}
                    linkFormId={field.link_form_id}
                    targetCategory={targetCategory}
                />
            )
        case 26:
            return (
                <SwitchInput
                    field={field}
                    changeHandler={val => changeHandler(field.name, val)}
                />
            )
        case 27:
            return (
                <FormContentsLink
                    value={field.value}
                    label={field.caption}
                    setting={field.setting}
                    changeHandler={val => changeHandler(field.name, val)}
                    linkFormId={field.link_form_id}
                    targetCategory={targetCategory}
                />
            )
        case 28:
            return (
                <Address
                    field={field}
                    changeHandler={val => changeHandler(field.name, val)}
                />
            )
        default:
            return <></>
    }
}

export default memo(Mapper);