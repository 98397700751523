import { useId, memo, useState, useRef, useEffect } from "react";
import { faNumToEng } from "utils";
import { CSSTransition } from "react-transition-group";
import ErrorMessage from "components/form-generator/error-msg";
import MasterAPI from "service/master";
import { toast } from "react-toastify";
import Resend from "./resend";
import { useDispatch } from "react-redux";
import { overwriteState } from "redux/slices/main";
import { useNavigate } from "react-router-dom";

const regex = /^[0-9\b]+$/;
const Step2 = props => {
    const { phone, changeStep, signupProcess } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const inputId = useId();
    const inputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [code, setCode] = useState();

    useEffect(() => {
        if (inputRef)
            inputRef.current.focus();
    }, [])

    const goBack = () => {
        setCode(null);
        changeStep(1);
    }

    const validate = value => {
        try {
            if (!value?.length)
                throw Object.assign(new Error("required"), { code: 1 });
            else if (!regex.test(value) || value?.length !== 5)
                throw Object.assign(new Error("format"), { code: 2 });
            else
                setError(null);

            return true;
        } catch (e) {
            setError(e.message);
            return false;
        }
    }

    const changeHandler = e => {
        const value = faNumToEng(e.target.value);

        if (value === "" || (value.length <= 5 && regex.test(value))) {
            setCode(value);
            validate(value);
        }
    }

    const submitHandler = async e => {
        e.preventDefault();

        if (loading)
            return;

        if (!validate(code)) {
            inputRef.current.focus();
            return;
        }

        setLoading(true);

        const data = {
            username: phone,
            code
        };

        if (signupProcess)
            data.signup = true;

        try {
            const res = await MasterAPI({
                url: "/api/user/signin_confirm",
                data,
                sendAuthorizationHeader: false,
                callback: () => setLoading(false)
            });

            const {
                access_token,
                access_token_expires_in,
                refresh_token,
                refresh_token_expires_in
            } = res;

            localStorage.setItem("_at", access_token);
            localStorage.setItem("_atet", access_token_expires_in);

            localStorage.setItem("_rt", refresh_token);
            localStorage.setItem("_rtet", refresh_token_expires_in);

            dispatch(overwriteState({
                authorizationProcess: true,
                authorization: true,
                accessToken: access_token,
                accessTokenExpireTime: refresh_token_expires_in
            }));

            navigate("/panel");
        } catch (e) {
            toast.error(e.message);
        }
    }

    return (
        <>
            <h1 className="tp-27 font-bold mb-2">اهراز هویت</h1>
            <p className="tp-28 text-sm mb-1">کد اهراز هویت برای شماره {phone} ارسال شد. کد مورد نظر را در فیلد زیر وارد نمایید:</p>

            <button className="mb-8 text-sm text-[#6259ca] hover:text-[#4c4597] transition-colors" onClick={goBack}>
                تغییر شماره همراه
            </button>

            <form onSubmit={submitHandler}>
                <div className={`text-field${code?.length ? " has-val" : ""}${!!error ? " fi-error" : ""}`}>
                    <label htmlFor={inputId}>کد</label>
                    <input
                        type="text"
                        value={code || ""}
                        onChange={changeHandler}
                        id={inputId}
                        ref={inputRef} />

                    {!!error && <span className="f-error">{ErrorMessage(error)}</span>}
                </div>

                <button type="submit" className="go-btn">
                    ورود

                    <CSSTransition in={loading} timeout={200} unmountOnExit={true}>
                        <span className="pos-cover bg-primary-200 fit-center anim-c4">
                            <i className="fa-solid fa-spinner spin"></i>
                        </span>
                    </CSSTransition>
                </button>
            </form>

            <div className="mt-4">
                <Resend phone={phone} />
            </div>
        </>
    )
}

export default memo(Step2);