import { memo, useCallback, useEffect, useState, useContext } from "react";
import FieldMapper from "components/form-generator/fields/mapper";
import { useLocation, useNavigate } from "react-router-dom";
import { urlDecoder, urlEncoder } from "utils";
import EnumsContext from "context/initialize-context";
import readTime from "./converter/read-time";
import sendTime from "./converter/send-time";

const SearchForm = props => {
    const {
        fields: orgFields,
        doneCallback = () => { }
    } = props;

    const enumsData = useContext(EnumsContext);
    const [fields, setFields] = useState({});
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (orgFields) {
            const newFields = {};
            const defParams = new URLSearchParams(location.search);

            orgFields.forEach(field => {
                let thisField = { ...field };

                if (field.data_type === 6) {
                    const localValue = [];

                    defParams.getAll(field.name).forEach(val => {
                        localValue.push(isNaN(val) ? val : parseInt(val));
                    })

                    thisField.value = localValue;
                } else {
                    const urlValue = defParams.get(field.name);
                    thisField.value = urlValue ? urlDecoder(urlValue) : null;
                }

                newFields[field.name] = thisField;
            })

            Object.keys(newFields).forEach(fieldKey => {
                newFields[fieldKey] = readTime(newFields[fieldKey]);
            })

            setFields(newFields);
        }

        // eslint-disable-next-line
    }, [orgFields])

    const changeHandler = useCallback((name, value) => {
        setFields(prev => {
            const newState = {
                ...prev,
            };

            newState[name] = {
                ...prev[name],
                value
            }

            return newState;
        });
    }, [])

    const submitHandler = e => {
        e.preventDefault();
        const params = new URLSearchParams(location.search);
        const newParams = new URLSearchParams();

        Object.keys(fields).forEach(fieldKey => {
            const field = fields[fieldKey];

            if (field.data_type === 6) {
                // if (typeof field.value === "object" && field.value?.length)
                //     newParams.set(fieldKey, urlEncoder(field.value.join(",")));

                field.value.forEach(el => {
                    newParams.append(fieldKey, el);
                })
            } else
                if (field.value || field.value === 0)
                    newParams.set(fieldKey, urlEncoder(sendTime(field)));
        })

        if (params.get("key"))
            newParams.set("key", urlDecoder(params.get("key")));

        newParams.delete("offset");
        doneCallback();
        navigate(`?${newParams.toString()}`);
    }

    return (
        <form onSubmit={submitHandler}>
            <div className="grid grid-cols-2 gap-4 form-base">
                {
                    Object.keys(fields).map(fieldKey => {
                        let field = fields[fieldKey];
                        const halfRow = field.masterclass && field.masterclass.indexOf("half-row") !== -1;

                        if (field.enumeration_id && !isNaN(field.enumeration_id)) {
                            const options = [];
                            enumsData.data.formEnumerations.forEach(el => {
                                if (el.parent_id === field.enumeration_id)
                                    options.push({ title: el.title, value: el.id });
                            })

                            field = { ...field, options };
                        }

                        return (
                            <div key={`form-fields-${fieldKey}`} className={`field${!halfRow ? " col-span-2" : ""}`}>
                                <FieldMapper
                                    field={field}
                                    changeHandler={changeHandler}
                                />
                            </div>
                        )
                    })
                }

                <div className="col-span-2">
                    <button type="submit" className="w-full d-block text-white bg-primary-100 rounded-md text-sm py-3 hover:bg-primary-200 transition-colors">
                        اعمال فیلتر
                    </button>
                </div>
            </div>
        </form>
    )
}

export default memo(SearchForm);